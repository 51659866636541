import { LoadingSpinner } from '@sparkuniverse/react-common/components';
import React, { lazy, useContext } from 'react';
import { FiDollarSign, FiHome } from 'react-icons/fi';
import { Redirect } from 'react-router-dom';
import { orgContext } from 'stores/OrgStore';

export enum RouteID {
    LOGIN = 'login',
    SETUP = 'setup',
    TOS = 'tos',

    DASHBOARD = 'dashboard',
}

export const ROUTES: { [key: string]: App.Route } = {
    login: {
        type: 'page',
        path: '/auth/login',
        page: lazy(() => import('../pages/auth/login')),
        breadcrumb: 'Login',
    },
    setup: {
        type: 'page',
        path: '/account/setup',
        page: lazy(() => import('../pages/accounts/setup')),
        conditions: [loggedIn, notSetupFinished],
        breadcrumb: 'Setup',
    },
    tos: {
        type: 'page',
        path: '/account/tos',
        page: lazy(() => import('../pages/accounts/tos')),
        conditions: [loggedIn, setupFinished, notTermsAccepted],
        breadcrumb: 'TOS',
    },

    dashboard: {
        type: 'page',
        path: '/',
        exact: true,
        page: lazy(() => import('../pages')),
        breadcrumb: 'Dashboard',
        nav: true,
        icon: FiHome,
        conditions: [loggedIn, finishedRequirements, orgsLoaded],
    },

    revenue: {
        type: 'page',
        path: '/revenue',
        exact: true,
        page: lazy(() => import('../pages/revenue')),
        breadcrumb: 'Revenue',
        nav: true,
        icon: FiDollarSign,
        conditions: [loggedIn, finishedRequirements, orgsLoaded],
    },
};

function loggedIn(
    _: App.BaseRoute,
    user?: User.BaseUser
): App.ConditionCheckResult {
    if (!user) {
        return {
            success: false,
            renderComponent: <Redirect to={ROUTES[RouteID.LOGIN].path} />,
        };
    }
    return { success: true };
}

function setupFinished(
    _: App.BaseRoute,
    user?: User.BaseUser
): App.ConditionCheckResult {
    if (!(user as User.PartnerUser).setup) {
        return {
            success: false,
            renderComponent: <Redirect to={ROUTES[RouteID.SETUP].path} />,
        };
    }
    return { success: true };
}

function notSetupFinished(
    _: App.BaseRoute,
    user?: User.BaseUser
): App.ConditionCheckResult {
    if ((user as User.PartnerUser).setup) {
        return {
            success: false,
            renderComponent: <Redirect to={ROUTES[RouteID.DASHBOARD].path} />,
        };
    }
    return { success: true };
}

function termsAccepted(
    _: App.BaseRoute,
    user?: User.BaseUser
): App.ConditionCheckResult {
    if (!(user as User.PartnerUser).terms) {
        return {
            success: false,
            renderComponent: <Redirect to={ROUTES[RouteID.TOS].path} />,
        };
    }
    return { success: true };
}

function notTermsAccepted(
    _: App.BaseRoute,
    user?: User.BaseUser
): App.ConditionCheckResult {
    if ((user as User.PartnerUser).terms) {
        return {
            success: false,
            renderComponent: <Redirect to={ROUTES[RouteID.DASHBOARD].path} />,
        };
    }
    return { success: true };
}

function finishedRequirements(
    _: App.BaseRoute,
    user?: User.BaseUser
): App.ConditionCheckResult {
    const setup = setupFinished(_, user);
    if (!setup.success) {
        return setup;
    }
    return termsAccepted(_, user);
}

function orgsLoaded(): App.ConditionCheckResult {
    const orgStore = useContext(orgContext);

    if (orgStore.state.isLoading) {
        return {
            success: false,
            renderComponent: <LoadingSpinner />,
        };
    }
    return { success: true };
}

// function hasOrgRole(role: string) {
//     return function _(
//         _: App.BaseRoute,
//         user?: User.BaseUser
//     ): App.ConditionCheckResult {
//         // TODO check if user has role
//         return { success: true };
//     };
// }
