// eslint-disable-next-line @typescript-eslint/no-var-requires
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import { ErrorPage } from '@sparkuniverse/react-common/pages';
import { AuthStateProvider } from '@sparkuniverse/react-common/stores';
import { getToken } from '@sparkuniverse/react-common/utils';
import { checkVersion } from '@sparkuniverse/react-common/utils/version';
import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { OrgStateProvider } from 'stores/OrgStore';
import { API_ROUTES, COOKIE_KEY } from 'utils/constants';
import App from './App';
import './base.css';
import customTheme from './utils/custom-theme';

window.commonConstants = {
    apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3000',
    cookieKey: COOKIE_KEY,
    customTheme,
};

checkVersion();

ReactDOM.render(
    <ErrorBoundary fallback={<ErrorPage code={500} />}>
        <React.StrictMode>
            <ChakraProvider theme={customTheme}>
                <CSSReset />
                <AuthStateProvider
                    apiUserRoute={API_ROUTES.account.user}
                    initialState={{ token: getToken() }}
                >
                    <OrgStateProvider initialState={{ isLoading: true }}>
                        <App />
                    </OrgStateProvider>
                </AuthStateProvider>
            </ChakraProvider>
        </React.StrictMode>
    </ErrorBoundary>,
    document.getElementById('root')
);
